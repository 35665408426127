import React from "react"
import styled from "styled-components"

const RawContent = ({ content = "" }) => {
  return (
    <Container>
      <Content
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      ></Content>
    </Container>
  )
}

export default RawContent

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 50px;
  margin-top: 80px;
`

const Content = styled.div`
  text-align: center;
  font-family: Poppins;

  p {
    margin-top: 30px;
  }
`
