import React from "react"

import CompactLoader from "./loaders/compact-loader"

const LoaderButton = ({
  text,
  onClick = () => {},
  type = "submit",
  className = "",
  loading = false,
  loaderColour = false,
  loaderHeight = "100%",
  padding = "10px",
  ...args
}) => {
  return (
    <button
      className={className}
      onClick={onClick}
      type={type}
      {...args}
    >
      {loading ? (
        <CompactLoader
          colour={loaderColour}
          height={loaderHeight}
          padding={padding}
        />
      ) : (
        text
      )}
    </button>
  )
}

export default LoaderButton
