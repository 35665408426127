import React from "react"
import BannerCarousel from "../components/shortcodes/banner-carousel"
import Cards from "../components/shortcodes/cards"
import TrendingRow from "../components/home-page/trending-row"
import BlogCards from "../components/shortcodes/blog-cards"

import * as cheerio from "cheerio"
import TextIcons from "../components/shortcodes/text-icons"
import IconCards from "../components/shortcodes/icon-cards"
import TeamCards from "../components/shortcodes/team-cards"
import RawContent from "../components/shortcodes/raw-content"

const parseShortcodes = (contents) => {
  const $ = cheerio.load(contents)

  var components = []

  $(".swiftcom-shortcode").each((i, el) => {
    if (el.attribs.class.match(/\scards\s/)) {
      components.push(
        parseShortcodeCardsContainer(cheerio.load(el), el.attribs.class)
      )
    } else if (el.attribs.class.match(/\sbanner-carousel\s/)) {
      components.push(
        parseShortcodeBannerCarousel(cheerio.load(el), el.attribs.class)
      )
    } else if (el.attribs.class.match(/\stop-sellers-carousel\s/)) {
      components.push(
        parseShortcodeTopSellersCarousel(cheerio.load(el), el.attribs.class)
      )
    } else if (el.attribs.class.match(/blog-cards/)) {
      components.push(
        parseShortcodeBlogCards(cheerio.load(el), el.attribs.class)
      )
    } else if (el.attribs.class.match(/\stext-icons\s/)) {
      components.push(parseShortcodeTextIcons(cheerio.load(el)))
    } else if (el.attribs.class.match(/\sicon-cards\s/)) {
      components.push(parseShortcodeIconCards(cheerio.load(el)))
    } else if (el.attribs.class.match(/\steam-cards\s/)) {
      components.push(parseShortcodeTeamCards(cheerio.load(el)))
    } else if (el.attribs.class.match(/raw-content/)) {
      components.push(ParseShortcodeRawContent(cheerio.load(el)))
    }
  })

  return components
}

const parseShortcodeCardsContainer = ($, className) => {
  var titles = $(".image-titles")
    .text()
    .split(",")
    .map((x) => x.trim())

  var slugs = $(".slugs")
    .text()
    .split(",")
    .map((x) => x.trim())

  var title = $(".title").text()

  var subtitle = $(".subtitle").text()

  var images = []
  $("img").each((i, el) => {
    images.push(el.attribs.src)
  })

  return (
    <Cards
      data={{
        images,
        titles,
        slugs,
        class: className,
        title: title,
        subtitle: subtitle,
      }}
    ></Cards>
  )
}

const parseShortcodeBannerCarousel = ($) => {
  var bannerContent = []
  $(".banner-carousel-item").each((i, el) => {
    bannerContent.push($(".banner-carousel-item").eq(i).html())
  })

  var images = []
  $("img").each((i, el) => {
    images.push(el.attribs.src)
  })

  return <BannerCarousel data={{ images, bannerContent }}></BannerCarousel>
}

const parseShortcodeTopSellersCarousel = ($) => {
  return <TrendingRow></TrendingRow>
}

const parseShortcodeBlogCards = ($) => {
  var slugs = $(".slugs")
    .text()
    .split(",")
    .map((x) => x.trim())

  var titles = $(".titles")
    .text()
    .split(",")
    .map((x) => x.trim())

  var read_times = $(".read_times")
    .text()
    .split(",")
    .map((x) => x.trim())

  var authors = $(".authors")
    .text()
    .split(",")
    .map((x) => x.trim())

  var dates = $(".dates")
    .text()
    .split(",")
    .map((x) => x.trim())

  var images = []
  $("img").each((i, el) => {
    images.push(el.attribs.src)
  })

  return (
    <BlogCards
      data={{
        title: "Blog",
        subtitle: "The latest news",
        slugs,
        titles,
        read_times,
        authors,
        dates,
        images,
      }}
    ></BlogCards>
  )
}

const parseShortcodeTextIcons = ($) => {
  var content = []
  $(".text-icons-item").each((i, el) => {
    let x = cheerio.load(el)
    content.push({
      title: x("h6")[0]?.children[0]?.data || null,
      image: x("img")[0]?.attribs?.src || null,
    })
  })

  var title = $(".title").text()
  var subtitle = $(".subtitle").text()

  return (
    <TextIcons content={content} title={title} subtitle={subtitle}></TextIcons>
  )
}

const parseShortcodeIconCards = ($) => {
  var content = []
  $(".icon-cards-item").each((i, el) => {
    let x = cheerio.load(el)
    content.push({
      title: x("h3")[0]?.children[0]?.data || null,
      image: x("img")[0]?.attribs?.src || null,
      paragraph: x("h4")[0]?.children[0]?.data || null,
    })
  })

  return <IconCards content={content}></IconCards>
}

const ParseShortcodeRawContent = ($) => {
  return <RawContent content={$.html()}></RawContent>
}

const parseShortcodeTeamCards = ($) => {
  var title = $(".title").text()

  var subtitle = $(".subtitle").text()
  var content = []
  $(".team-cards-item").each((i, el) => {
    let x = cheerio.load(el)
    content.push({
      name: x(".member-name")[0]?.children[0]?.data || null,
      title: x(".member-title")[0]?.children[0]?.data || null,
      image: x("img")[0]?.attribs?.src || null,
      paragraph: x("h4")[0]?.children[0]?.data || null,
    })
  })

  return (
    <TeamCards title={title} subtitle={subtitle} content={content}></TeamCards>
  )
}

export { parseShortcodes }
