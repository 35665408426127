import React, { useContext, useState } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import LoaderButton from "../generic/loader-button"
import LinkWrapper from "../../lib/link-wrapper"
import ProductStarRating from "./product-star-rating"

import ToastNotification from "../../theme/toast-notification"
import CartContext from "../../context/cart/cart-context"
import { getProperty } from "../../lib/utils"
import { calculateDiscount, formatPrice } from "../../lib/format"
import { analyticsAddToCart } from "../../services/google-analytics"
import { pixelAddToCart } from "../../services/facebook-pixel"
import CloudflareImage from "../reusable/cloudflare-image"

const ProductCard = ({ product, width = "230px", className = "" }) => {
  const placeholderImgData = useStaticQuery(
    PRODUCT_CARD_PLACEHOLDER_IMAGE_QUERY
  )
  const [addToCartLoading, setAddToCartLoading] = useState(false)
  const [apiError, setApiError] = useState("")

  const { addToCart, openCartDrawer } = useContext(CartContext)

  // Product general info
  const title = getProperty(product, ["title"], "")
  const slug = product?.parent_slug || product?.slug || ""
  const rating = getProperty(product, ["averageRating"], "")
  let link = `/product/${slug}/`
  if (product?.type === "variation") {
    link = `${link}?select=${product.database_id}`
  }

  // Product price info
  const price = product?.price || ""
  const regularPrice = product?.regular_price || ""
  const discount = calculateDiscount(price, regularPrice)

  // Prepare product image
  let productImage = product?.featured_image?.sourceUrl
  const placeholderImg = getImage(placeholderImgData?.file)

  // Handle the add-to-cart button
  const handleAddToCart = () => {
    setAddToCartLoading(true)
    analyticsAddToCart(product, 1)
    pixelAddToCart(product)
    addToCart({ id: product.database_id, quantity: 1 })
      .then(() => {
        console.log("DEBUG: handleAddToCart SUCCESS catch")
        setAddToCartLoading(false)
        openCartDrawer(true)
      })
      .catch((error) => {
        setApiError(error)
        setAddToCartLoading(false)
      })
  }

  return (
    <ProductCardStyling className={className} width={width}>
      {!discount ? null : <DiscountStyling>{`-${discount}%`}</DiscountStyling>}
      <LinkWrapper to={link}>
        <ProductImgStyling className={"full-width-inner-section"}>
          {productImage ?
            <CloudflareImage
              src={productImage}
              alt={`${title}-image`}
              objectFit="contain"
            />
            :
            <GatsbyImage
              image={placeholderImg}
              alt={`${title}-image`}
              objectFit="contain"
              imgStyle={{ width: "100%", height: "100%" }}
            />
          }
        </ProductImgStyling>
      </LinkWrapper>
      <div className="product-card-bottom-wrapper">
        <div className="data-hover-effect">
          <LinkWrapper to={link}>
            <h6 dangerouslySetInnerHTML={{ __html: title }} />
          </LinkWrapper>
          {discount ? (
            <PricingStyling>
              <span>{formatPrice(regularPrice)}</span>
              <span>{formatPrice(price)}</span>
            </PricingStyling>
          ) : (
            <PricingStyling>{formatPrice(price)}</PricingStyling>
          )}
          {rating < 0.5 ? null : <ProductStarRating rating={rating} />}
        </div>
        <LoaderButton
          text="Add to cart"
          loading={addToCartLoading}
          loaderColour="white"
          onClick={handleAddToCart}
          padding="0"
        />
        <ToastNotification message={apiError} />
      </div>
    </ProductCardStyling>
  )
}

export default ProductCard

// ===============
//     STYLES
// ===============
const ProductCardStyling = styled.div`
  position: relative;
  border-radius: 5px;
  width: ${({ width }) => width};
  button {
    width: 100%;
    max-height: 53px;
    margin-top: calc(138px - 53px);
  }
  .data-hover-effect {
    z-index: 2;
    position: absolute;
    bottom: 0;
    transition: all 0.3s ease-in-out;
    width: 100%;
    min-height: 138px;
    text-align: center;
    padding: 15px;
    background: white;
    box-shadow: 0px 3px 20px #00000014;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .hover-image {
    display: none;
  }
  .product-card-bottom-wrapper:hover > .data-hover-effect {
    bottom: 53px;
    transition: all 0.3s ease-in-out;
  }
  .full-width-inner-section:hover > .hover-image {
    display: block;
  }
  .full-width-inner-section:hover > .main-image {
    display: none;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    &.fullwidthcards {
      width: 250px;
      margin: 0 auto;
    }
    .product-card-bottom-wrapper:hover > .data-hover-effect {
      bottom: 0;
    }
    .full-width-inner-section:hover > .hover-image {
      display: none;
    }
    .full-width-inner-section:hover > .main-image {
      display: block;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    &.fullwidthcards {
      width: 100%;
    }
  }
`
const DiscountStyling = styled.div`
  position: absolute;
  right: 0;
  z-index: 50;
  vertical-align: middle;
  width: 60px !important;
  height: 40px;
  margin-right: 0 !important;
  border-radius: 1px;
  background-color: #ffcb00;
  color: #1c1c1c;
  font-weight: 300;
  text-align: center;
  line-height: 40px;
`
const ProductImgStyling = styled.div`
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  img {
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    img {
      width: 100%;
    }
    &.full-width-inner-section {
      margin-bottom: 0rem;
    }
  }
`
const PricingStyling = styled.h6`
  & span:first-child {
    padding-right: 10px;
  }
  & span:not(:last-child) {
    padding-right: 10px;
    color: ${({ theme }) => theme.colors.lightgrey5};
    text-decoration: line-through;
  }
`

// ===============
//      QUERY
// ===============
const PRODUCT_CARD_PLACEHOLDER_IMAGE_QUERY = graphql`
  query {
    file(relativePath: { eq: "placeholder.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
