import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import CloudflareImage from "../reusable/cloudflare-image"

const BlogCards = ({ data }) => {
  return (
    <ContainerStyled>
      {data.title !== "none" ? <h2>{data.title}</h2> : null}
      {data.subtitle !== "none" ? (
        <h4 className="light">{data.subtitle}</h4>
      ) : null}
      <div className="blog-cards-container">
        {data.images.length > 0
          ? data.images.map((img, index) => (
            <Link
              className="blog-card"
              key={index}
              to={`/blog/${data.slugs[index]}`}
            >
              <CloudflareImage src={img}></CloudflareImage>
              <div className="article-info-container">
                <h4>{data.titles[index]}</h4>
                <p className="read-time">{data.read_times[index]}</p>
                <p className="author">{`by ${data.authors[index]} · ${data.dates[index]}`}</p>
              </div>
            </Link>
          ))
          : "No blogs to display"}
      </div>
    </ContainerStyled>
  )
}
export default BlogCards

const ContainerStyled = styled.div`
  max-width: ${({ theme }) => theme.maxWidth};
  margin-left: auto;
  margin-right: auto;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;

  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    max-width: ${({ theme }) => theme.breakMedium};
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    max-width: 100%;
    padding: 0 30px;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    max-width: 100%;
    padding: 0 25px;
  }

  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    margin-top: 50px;
  }

  .blog-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;

    .blog-card {
      width: 100%;
      max-width: 375px;
      height: 432px;
      box-shadow: 0px 3px 20px #00000014;
      border-radius: 8px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      overflow: hidden;
      margin: 10px;

      @media (max-width: ${({ theme }) => theme.breakTiny}) {
        max-width: 350px;
        height: 375px;
      }

      & > .cloudflare-image-wrapper {
        width: 100%;
        min-height: 210px;
        max-height: 210px;
        .cloudflare-image-img {
          object-fit: cover;
        }
      }

      .article-info-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 0 50px;
        justify-content: space-between;
        padding: 10px 5% 15px 5%;

        & > h4 {
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          text-align: center;

          @media (max-width: ${({ theme }) => theme.breakTiny}) {
            -webkit-line-clamp: 2;
          }
        }

        .read-time {
          color: ${({ theme }) => theme.colors.brandHover};
          text-align: center;
          margin: 0;
          margin-top: auto;
        }

        .author {
          text-align: center;
          margin: 0;
          font-style: italic;
          color: ${({ theme }) => theme.colors.lightgrey5};
        }
      }
    }
  }
`
