import React from "react"
import styled from "styled-components"

import Carousel from "react-elastic-carousel"
import ProductCard from "../product-card"
import CarouselArrow from "./carousel-arrow"

// Breakpoints for small screens
const breakPointsbasedOnProducts = [
  { width: 1, itemsToShow: 1 },
  { width: 540, itemsToShow: 2 },
  { width: 720, itemsToShow: 3 },
  { width: 900, itemsToShow: 4 },
  { width: 1220, itemsToShow: 5 },
]

const ProductCarousel = ({ products, className = "" }) => {
  let onsSlideMaxWidth = 0
  for (let i = products.length; i < 5; ++i) {
    onsSlideMaxWidth = onsSlideMaxWidth + 232
  }

  return (
    <StyledCarousel
      onsSlideMaxWidth={onsSlideMaxWidth}
      className={className}
      pagination={false}
      showArrows={products.length > 1}
      disableArrowsOnEnd
      renderArrow={CarouselArrow}
      breakPoints={breakPointsbasedOnProducts}
    >
      {products.map((product, productIndex) => {
        return (
          <div key={productIndex}>
            <ProductCard product={product} width="230px" />
          </div>
        )
      })}
    </StyledCarousel>
  )
}

export default ProductCarousel

// ===============
//     STYLES
// ===============
const StyledCarousel = styled(Carousel)`
  .rec {
    outline: none;
    padding-bottom: 10px !important;
  }
  .rec-slider-container {
    max-width: ${({ theme }) => theme.maxWidth};
    width: calc(
      ${({ theme }) => theme.maxWidth} -
        ${({ onsSlideMaxWidth }) => onsSlideMaxWidth}px
    );
    margin-left: auto;
    margin-right: auto;
    @media (max-width: ${({ theme }) => theme.breakLarge}) {
      max-width: ${({ theme }) => theme.breakMedium};
      margin: 0 auto;
    }
    @media (max-width: ${({ theme }) => theme.breakMedium}) {
      max-width: calc(${({ theme }) => theme.breakMedium} - 160px);
      margin: 0 auto;
    }
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      max-width: 232px;
    }
  }
  .rec-carousel,
  .rec-carousel-wrapper {
    position: relative;
  }
  .rec-arrow {
    position: absolute;
    z-index: 1;
    top: 30%;
    height: 50px;
    width: 50px;
    padding: 0;
    color: ${({ theme }) => theme.colors.black1};
    background-color: white;
    transition: all 0.3s ease-in-out;
    border: none;
    border-radius: 100%;
    img {
      margin-bottom: 0;
    }
    @media (max-width: ${({ theme }) => theme.breakMedium}) {
      top: auto;
      bottom: 4.75rem;
    }
    @media (max-width: ${({ theme }) => theme.breakTiny}) {
      bottom: 4rem;
    }
  }
  .rec-arrow:hover:enabled {
    line,
    path {
      transition: all 0.3s ease-in-out;
      stroke: white;
    }
    transition: all 0.3s ease-in-out;
    background-color: ${({ theme }) => theme.colors.grey};
  }
  .rec-arrow-left {
    left: calc(
      calc(calc(100vw - ${({ theme }) => theme.maxWidth}) / 2) -
        calc(50px + 3vw)
    );
    @media (max-width: 1440px) {
      left: 0.98vw;
    }
    @media (max-width: ${({ theme }) => theme.breakMedium}) {
      left: 30px;
    }
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      left: 10px;
    }
  }
  .rec-arrow-right {
    svg {
      transform: scaleX(-1);
    }
    right: calc(
      calc(calc(100vw - ${({ theme }) => theme.maxWidth}) / 2) -
        calc(50px + 3vw)
    );
    @media (max-width: 1440px) {
      right: 0.98vw;
    }
    @media (max-width: ${({ theme }) => theme.breakMedium}) {
      right: 30px;
    }
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      right: 10px;
    }
  }
`
