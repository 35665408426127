import React from "react"
import styled from "styled-components"
import { graphql, Link, useStaticQuery } from "gatsby"

import ProductCarousel from "../products/product-carousel/product-carousel"

import { getProperty } from "../../lib/utils"

const TrendingRow = () => {
  const trendingQueryData = useStaticQuery(TRENDING_QUERY)

  const title = "Top Sellers"
  const subtitle = "What's hot"
  const buttonText = "Shop"
  const buttonURL = "/shop/"

  const trendingProducts = trendingQueryData.allSeaProduct.nodes

  return (
    <TrendingRowContainer>
      <h2 dangerouslySetInnerHTML={{ __html: title }} />
      <p dangerouslySetInnerHTML={{ __html: subtitle }} />
      <ProductCarousel products={trendingProducts} />
      <StyledButton className={`button-class`} to={`${buttonURL}`}>
        {buttonText}
      </StyledButton>
    </TrendingRowContainer>
  )
}

export default TrendingRow

// ===============
//     STYLES
// ===============
const TrendingRowContainer = styled.div`
  text-align: center;
  > p {
    margin: 0 0 3.125rem;
  }
  margin-top: 100px;
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    margin-top: 50px;
  }
  .rec-arrow {
    z-index: 5;
    background-color: ${({ theme }) => theme.colors.white}cc;
  }
  .rec-arrow-left {
    left: -100px;
    @media (max-width: calc(${({ theme }) => theme.breakLarge} + 250px)) {
      left: -50px;
    }
    @media (max-width: calc(${({ theme }) => theme.breakLarge} + 150px)) {
      left: 0;
    }
    @media (max-width: ${({ theme }) => theme.breakLarge}) {
      left: -5px;
    }
    @media (max-width: ${({ theme }) => theme.breakTiny}) {
      left: -25px;
    }
  }
  .rec-arrow-right {
    right: -100px;
    @media (max-width: calc(${({ theme }) => theme.breakLarge} + 250px)) {
      right: -50px;
    }
    @media (max-width: calc(${({ theme }) => theme.breakLarge} + 150px)) {
      right: 0;
    }
    @media (max-width: ${({ theme }) => theme.breakLarge}) {
      right: -5px;
    }
    @media (max-width: ${({ theme }) => theme.breakTiny}) {
      right: -25px;
    }
  }
`
const StyledButton = styled(Link)`
  width: fit-content;
  margin: 1.875rem auto 3.125rem !important;
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    width: fit-content !important;
  }
`

// ===============
//      QUERY
// ===============
export const TRENDING_QUERY = graphql`
  {
    allSeaProduct(limit: 8, sort: { fields: sales, order: DESC }) {
      nodes {
        database_id
        title
        slug
        brand
        price
        regular_price
        featured_image {
          sourceUrl
        }

        variations {
          type
          variation_name
          database_id
          title
          parent_slug
          slug
          price
          regular_price
          featured_image {
            sourceUrl
          }
          attributes {
            key
            values
          }
        }
      }
    }
  }
`
