import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import Carousel from "react-elastic-carousel"
import CarouselArrow from "../products/product-carousel/carousel-arrow"
import Banner from "./banner"

const BannerCarousel = ({ data }) => {
  const placeholderData = useStaticQuery(PLACEHOLDER_QUERY)
  const placeholderImage = placeholderData?.imageSharp?.original?.src || ""
  return (
    <StyledCarousel
      disableArrowsOnEnd={false}
      renderArrow={CarouselArrow}
      count={data.images.length}
      enableAutoPlay={true}
      autoPlaySpeed={30000}
    >
      {data.bannerContent.map((content, index) => (
        <Banner
          image={data.images[index] ? data.images[index] : placeholderImage}
          key={index}
        >
          {React.createElement("div", {
            dangerouslySetInnerHTML: { __html: content },
            className: "banner-carousel-content",
          })}
        </Banner>
      ))}
    </StyledCarousel>
  )
}
export default BannerCarousel

const StyledCarousel = styled(Carousel)`
  position: relative;
  height: fit-content;

  .rec-carousel {
    position: relative;
  }

  .rec-pagination {
    position: absolute;
    left: 50px;
    top: calc(50% - ((${({ count }) => count} -1) * 16px));
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;

    @media (max-width: ${({ theme }) => theme.breakTiny}) {
      transform: rotate(270deg);
      top: unset;
      bottom: 20px;
      left: calc(50% - ((${({ count }) => count}) * 5px));
    }
  }

  .rec-slider-container {
    width: 100%;
    margin: 0;
  }

  .rec-arrow {
    position: absolute;
    opacity: 1;
    width: 50px;
    height: 50px;
    z-index: 1;
    padding: 0;
    background-color: white;
    border: none;
    bottom: 30px;

    @media (max-width: ${({ theme }) => theme.breakTiny}) {
      bottom: 15px;
    }
  }

  .rec-arrow-left {
    right: 110px;

    @media (max-width: ${({ theme }) => theme.breakTiny}) {
      right: unset;
      left: 40px;
    }
  }

  .rec-arrow-right {
    right: 40px;
    svg {
      transform: rotate(180deg);
    }

    @media (max-width: ${({ theme }) => theme.breakTiny}) {
      right: 40px;
    }
  }

  .rec-dot {
    background-color: ${({ theme }) => theme.colors.darkgrey2};
    border: none;
    outline: none;
    box-shadow: none;
    width: 6px;
    height: 6px;

    &.rec-dot_active {
      background-color: ${({ theme }) => theme.colors.brandPrimary};
      width: 10px;
      height: 10px;
      border: none;
      padding: 2px;
      outline: 2px solid ${({ theme }) => theme.colors.brandPrimary};
      background-clip: content-box;
    }
  }
`

// ===============
//      QUERY
// ===============
export const PLACEHOLDER_QUERY = graphql`
  {
    imageSharp(fixed: { originalName: { eq: "placeholder-banner.jpg" } }) {
      original {
        src
      }
    }
  }
`
