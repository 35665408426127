import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

const IconCards = ({ content = [] }) => {
  const placeholderData = useStaticQuery(PLACEHOLDER_QUERY)
  const placeholderImage = placeholderData?.imageSharp?.original?.src || ""
  return (
    <Container>
      {content.map((item, index) => (
        <Card>
          <CardContent>
            <img src={item.image || placeholderImage}></img>
            <h2>{item.title || "No text"}</h2>
            <p>{item.paragraph || "No text"}</p>
          </CardContent>
          <IconBackground></IconBackground>
        </Card>
      ))}
    </Container>
  )
}

export default IconCards

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const Card = styled.div`
  width: 715px;
  height: 232px;
  box-shadow: 0px 5px 30px #0000001a;
  border-radius: 8px;
  position: relative;
  margin-top: 200px;
  margin-bottom: 80px;
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    margin-top: 150px;
  }

  img {
    position: absolute;
    top: -120px;
    left: calc(50% - 72.5px);
    z-index: 2;
    max-width: 145px;
  }

  h3 {
    text-align: center;
    margin-bottom: 10px;
  }

  p {
    text-align: center;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    width: 100%;
  }
`

const CardContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 50px;
`
const IconBackground = styled.div`
  width: 180px;
  height: 180px;
  border-radius: 100%;
  background-color: #1477c6;
  opacity: 0.05;
  position: absolute;
  top: -150px;
  left: calc(50% - 90px);
  z-index: 1;
`

// ===============
//      QUERY
// ===============
export const PLACEHOLDER_QUERY = graphql`
  {
    imageSharp(fixed: { originalName: { eq: "placeholder.jpg" } }) {
      original {
        src
      }
    }
  }
`
