import React from "react"

const SliderArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.75"
    height="10.753"
    viewBox="0 0 25.75 10.753"
  >
    <g
      id="Group_5"
      data-name="Group 5"
      transform="translate(25.75 10.381) rotate(180)"
    >
      <line
        id="Line_3"
        data-name="Line 3"
        x2="25.245"
        transform="translate(0 5.004)"
        fill="none"
        stroke="#121212"
        strokeWidth="1"
      />
      <path
        id="Path_3"
        data-name="Path 3"
        d="M0,0,5.584,5,0,10.008"
        transform="translate(19.417)"
        fill="none"
        stroke="#121212"
        strokeWidth="1"
      />
    </g>
  </svg>
)

export default SliderArrow
