import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import CloudflareImage from "../reusable/cloudflare-image"

const TeamCards = ({ title, subtitle, content = [] }) => {
  return (
    <Container>
      {title !== "none" ? <MainTitle>{title}</MainTitle> : null}
      {subtitle !== "none" ? <MainSubTitle>{subtitle}</MainSubTitle> : null}
      <CardContainer>
        {content.map((item, index) => (
          <Member>
            <Portrait src={item.image}></Portrait>
            <MemberNameContainer>
              <MemberName>{item.name || "No Text"}</MemberName>
              <MemberTitle className="light">
                {item.title || "No Text"}
              </MemberTitle>
            </MemberNameContainer>
            <MemberContent>
              <p>{item.paragraph}</p>
            </MemberContent>
          </Member>
        ))}
      </CardContainer>
    </Container>
  )
}

export default TeamCards

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 80px;
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    margin-bottom: 50px;
  }
`
const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 30px;
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    margin-top: 10px;
  }
`

const Member = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: 10px;
  margin-bottom: 50px;
  text-align: center;
  align-items: center;
`

const Portrait = styled(CloudflareImage)`
  width: 570px;
  height: 570px;
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    width: 90%;
    height: unset;
  }
  .cloudflare-image-img {
    border-radius: 8px 8px 0 0;
    object-fit: cover;
    object-position: center top;
  }
`

const MemberContent = styled.div`
  text-align: center;
  margin-top: 30px;
  width: 570px;
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    width: 100%;
  }
`

const MemberName = styled.h3`
  text-align: center;
`

const MemberTitle = styled.h4`
  text-align: center;
  color: ${({ theme }) => theme.colors.brandHover};
`

const MemberNameContainer = styled.div`
  width: 590px;
  height: 108px;
  box-shadow: 0px 5px 30px #0000001a;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    width: 100%;
  }
`

const MainTitle = styled.h2`
  margin-bottom: 30px;
  text-align: center;
`

const MainSubTitle = styled.h3`
  text-align: center;
`