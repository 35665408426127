import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

const TextIcons = ({ content = [], title, subtitle }) => {
  const placeholderData = useStaticQuery(PLACEHOLDER_QUERY)
  const placeholderImage = placeholderData?.imageSharp?.original?.src || ""
  return (
    <Container>
      {title !== "none" ? <MainTitle>{title}</MainTitle> : null}
      {subtitle !== "none" ? (
        <MainSubTitle className="large">{subtitle}</MainSubTitle>
      ) : null}
      <CardContainer>
        {content.map((val, index) => (
          <Card>
            <ImgContainer>
              <img src={val.image || placeholderImage} />
            </ImgContainer>
            {val.title || "No text"}
          </Card>
        ))}
      </CardContainer>
    </Container>
  )
}

export default TextIcons

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 80px;
`
const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 50px;
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    margin-top: 10px;
  }
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  margin: 10px 20px 40px 20px;
  text-align: center;
  align-items: center;
`
const ImgContainer = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    margin-bottom: 15px;
  }

  img {
    max-width: 80px;
    max-height: 80px;
  }
`

const MainTitle = styled.h2`
  margin-bottom: 20px;
  text-align: center;
`

const MainSubTitle = styled.p`
  text-align: center;
`

// ===============
//      QUERY
// ===============
export const PLACEHOLDER_QUERY = graphql`
  {
    imageSharp(fixed: { originalName: { eq: "placeholder.jpg" } }) {
      original {
        src
      }
    }
  }
`
