import React from "react"
import styled from "styled-components"
import CloudflareImage from "../reusable/cloudflare-image"

const Banner = ({ children, image = "" }) => {
  return (
    <Container>
      <ImgStyled src={image}></ImgStyled>
      <InfoContainer>{children}</InfoContainer>
    </Container>
  )
}

export default Banner

const Container = styled.div`
  width: 100%;
  height: 550px;
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    height: 700px;
  }
`

const ImgStyled = styled(CloudflareImage)`
  width: 100%;
  height: 100%;
  .cloudflare-image-img {
    object-fit: cover;
  }
`
const InfoContainer = styled.div`
  position: absolute;
  width: 575px;
  max-width: 575px;
  height: 100%;
  top: 0;
  left: 15%;

  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    width: 100%;
    height: 100%;
    padding: 0 30px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .banner-carousel-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    h2 {
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 50px;
    }
    .wp-block-buttons {
      width: fit-content;

      @media (max-width: ${({ theme }) => theme.breakTiny}) {
        margin: 0 auto;
      }
    }

    .wp-block-button {
      width: fit-content;
      margin-right: 0;
      a {
        background: ${({ theme }) => theme.colors.brandPrimary} 0% 0% no-repeat
          padding-box;
        border-radius: 25px;
        width: 250px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 1rem;
        line-height: 1.5rem;
        margin: 0;
      }
    }
  }
`
