import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import CloudflareImage from "../reusable/cloudflare-image"

const Cards = ({ data }) => {
  return (
    <ContainerStyled className={data.class}>
      {data.title !== "none" ? <h2>{data.title}</h2> : null}
      {data.subtitle !== "none" ? (
        <h4 className="light">{data.subtitle}</h4>
      ) : null}
      <CardsContainer>
        {data.images.map((img, index) => (
          <CardStyled key={index} className="card" to={`/${data.slugs[index]}`}>
            <ImgStyled src={img}></ImgStyled>
            <TitleContainer>
              <TitleStyled>{data.titles[index]}</TitleStyled>
            </TitleContainer>
          </CardStyled>
        ))}
      </CardsContainer>
    </ContainerStyled>
  )
}
export default Cards

const ContainerStyled = styled.div`
  max-width: ${({ theme }) => theme.maxWidth};
  margin-left: auto;
  margin-right: auto;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;

  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    max-width: ${({ theme }) => theme.breakMedium};
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    max-width: 100%;
    padding: 0 30px;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    max-width: 100%;
    padding: 0 25px;
  }
  &.middle-large {
    .card:nth-of-type(3n-1) {
      width: calc(295px * 2);

      @media (max-width: ${({ theme }) => theme.maxWidth}) {
        width: 295px;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    margin-top: 50px;
  }
`

const CardsContainer = styled.div`
  margin-top: 30px;
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
`

const CardStyled = styled(Link)`
  cursor: pointer;
  margin: 10px;
  width: 295px;
  height: 375px;
  box-shadow: 0px 3px 20px #00000014;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
`

const ImgStyled = styled(CloudflareImage)`
  width: 100%;
  height: 100%;
  .cloudflare-image-img {
    object-fit: contain;
    object-position: 50% top;
    image-orientation: none;
  }
`
const TitleContainer = styled.div`
  padding: 0 10px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  height: 80px;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 0 0 8px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const TitleStyled = styled.h3`
  line-height: 2rem;
  font-weight: 500;
  letter-spacing: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`