import React from "react"
import { consts } from "react-elastic-carousel"
import SliderArrow from "../../../images/icons/slider-arrow"

const CarouselArrow = ({ type, onClick }) => {
  const arrowClass = type === consts.PREV ? "rec-arrow-left" : "rec-arrow-right"
  return (
    <button onClick={onClick} className={arrowClass + " rec-arrow"}>
      <SliderArrow />
    </button>
  )
}

export const ControlledCarouselArrow = ({
  type,
  delay = 0,
  beforeDelay = () => {},
  afterDelay = () => {},
  onClick = () => {},
}) => {
  const arrowClass = type === consts.PREV ? "rec-arrow-left" : "rec-arrow-right"
  return (
    <button
      onClick={() => onClick(arrowClass)}
      className={arrowClass + " rec-arrow"}
    >
      <SliderArrow />
    </button>
  )
}

export default CarouselArrow
